<template>
  <div>
    <div>
      <h3 class="card-label">
        {{ $t('work_order.work_order') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('work_order.title') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.title" class="form-control" :class="validation && validation.title ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.title" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.title[0] }}
                            </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('work_order.bill_of_material') }}<span class="text-danger">*</span></label>
                <multiselect
                    v-model="bill_material"
                    :placeholder="$t('work_order.bill_of_material')"
                    :class="validation && validation.bill_material_id ? 'is-invalid' : ''"
                    label="name"
                    track-by="id"
                    @input="getBillMaterial($event)"
                    :options="bill_material_list"
                    :multiple="false"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.bill_material_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.bill_material_id[0] }}
                            </span>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label for="in_progress_inventory">{{ $t('work_order.in_progress_inventory') }}</label>
                <div class="input-group mb-3">
                  <multiselect v-model="in_progress_inventory"
                               id="in_progress_inventory"
                               :placeholder="$t('work_order.in_progress_inventory')"
                               :class="validation && validation.in_progress_inventory_id ? 'is-invalid' : ''"
                               label="name"
                               track-by="id"
                               :options="inventories"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/inventories/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.in_progress_inventory_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.in_progress_inventory_id[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label for="target_inventory">{{ $t('work_order.target_inventory') }}</label>
                <div class="input-group mb-3">
                  <multiselect v-model="target_inventory"
                               id="target_inventory"
                               :placeholder="$t('work_order.target_inventory')"
                               :class="validation && validation.target_inventory_id ? 'is-invalid' : ''"
                               label="name"
                               track-by="id"
                               :options="inventories"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/inventories/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.target_inventory_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.target_inventory_id[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label for="scrap_inventory">{{ $t('work_order.scrap_inventory') }}</label>
                <div class="input-group mb-3">
                  <multiselect v-model="scrap_inventory"
                               id="scrap_inventory"
                               :placeholder="$t('work_order.scrap_inventory')"
                               :class="validation && validation.scrap_inventory_id ? 'is-invalid' : ''"
                               label="name"
                               track-by="id"
                               :options="inventories"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/inventories/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.scrap_inventory_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.scrap_inventory_id[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mt-10 mb-10">
                <b-form-checkbox size="lg" v-model="data.skip_material_transfer" name="check-button" switch>{{ $t('work_order.skip_material_transfer') }}</b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('work_order.item') }}<span class="text-danger">*</span></label>
                <div class="d-flex search-item-group">
                  <select name="" id="" v-model="f_by" class="custom-select" style="width: 100px;">
                    <option value="name" selected>{{ $t('items.name') }}</option>
                    <option value="sku_code">{{ $t('items.sku_code') }}</option>
                  </select>
                  <multiselect v-model="item"
                               id="item_id"
                               :placeholder="$t('work_order.item')"
                               :label="f_by?f_by:'sku_code'"
                               :class="validation && validation.item_id ? 'is-invalid' : ''"
                               track-by="id"
                               :options="items_manufacture"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               :internal-search="false"
                               @search-change="getItemsManufacture(f_by, $event)">
                  </multiselect>
                </div>
                <span v-if="validation && validation.item_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.item_id[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('work_order.actual_quantity') }}</label>
                <input type="number" min="0" v-model="data.actual_quantity" class="form-control" :class="validation && validation.actual_quantity ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.actual_quantity" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.actual_quantity[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('work_order.expected_qty') }}</label>
                <input type="number" min="1" v-model="data.expected_qty" class="form-control" :class="validation && validation.expected_qty ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.expected_qty" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.expected_qty[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('work_order.sales_no') }}</label>
                <input type="number" min="0" v-model="data.sales_no" class="form-control" :class="validation && validation.sales_no ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.sales_no" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.sales_no[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('work_order.project_no') }}</label>
                <input type="number" min="0" v-model="data.project_no" class="form-control" :class="validation && validation.project_no ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.project_no" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.project_no[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('work_order.status') }}</label>
                <select name="" id="status" disabled v-model="data.status" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                  <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.status[0] }}
                                    </span>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-12 mt-10 mb-10">
                <b-form-checkbox size="lg" v-model="data.use_multilevel_bom" name="check-button" switch>{{ $t('work_order.use_multilevel_bom') }}</b-form-checkbox>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('work_order.notes') }}</label>
                <textarea v-model="data.notes" class="form-control" rows="5" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-lg-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('work_order.materials') }}</h6>
            <button type="button" class="btn btn-primary" @click="addRepeaterItem"><i class="fas fa-plus"></i>{{ $t('add_more') }}</button>
          </div>
          <div class="table-responsive bg-white">
            <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addRepeaterItem" @keyup.alt.46="removeRepeaterItem">
              <thead>
              <tr>
                <th width="250px">{{ $t('work_order.item_details') }}</th>
                <th width="300px">{{ $t('work_order.item') }}</th>
                <th width="150px">{{ $t('work_order.origin_quantity') }}</th>
                <th width="150px">{{ $t('work_order.required_quantity') }}</th>
                <th scope="col">
                  <div class="d-flex justify-content-between">
                    <span>{{ $t('work_order.inventory') }}</span>
                    <a href="/settings/inventories/create" target="_blank" class="btn btn-primary btn-sm p-1">
                      <i class="fa fa-plus add-new"></i>
                    </a>
                  </div>
                </th>
                <th width="150px">{{ $t('work_order.notes') }}</th>
                <th>{{ $t('actions') }}</th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="(row, index) in sub_items" :key="index">
                <td>
                  <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item" class="d-flex cursor-pointer">
                    <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                    <div class="pl-2 pr-2 text-dark">
                      <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}: {{ row.item.sku_code }}</p>
                      <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                      <p class="mb-1" v-if="row.item.sale_price">{{ $t('items.sale_price') }}: {{ row.item.sale_price }}</p>
                      <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}: {{ row.item.purchase_price }}</p>
                      <p class="mb-1" v-if="row.item.sum_available_qty">{{ $t('items.sum_available_qty') }}: {{ row.item.sum_available_qty }}</p>
                    </div>
                  </a>
                </td>
                <td>
                  <div class="d-flex search-item-group" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('work_order.item')">
                    <select name="" v-model="row.f_by" class="custom-select" style="width: 100px;">
                      <option value="name" selected>{{ $t('items.name') }}</option>
                      <option value="sku_code">{{ $t('items.sku_code') }}</option>
                    </select>
                    <multiselect v-model="row.item"
                                 :disabled="row.is_disabled"
                                 :placeholder="$t('work_order.item')"
                                 :label="row.f_by? row.f_by:'name'"
                                 track-by="id"
                                 :options="items_repeater_select"
                                 @input="checkItemSelected(index, $event)"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                  </div>

                </td>
                <td><input v-model="row.origin_quantity" :disabled="row.is_disabled" type="number" class="form-control"></td>
                <td><input v-model="row.required_quantity" :disabled="row.is_disabled" type="number" class="form-control"></td>
                <td>
                  <multiselect v-model="row.inventory"
                               :placeholder="$t('work_order.inventory')"
                               label="name"
                               track-by="id"
                               :options="inventories"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                </td>
                <td><textarea v-model="row.notes" class="form-control"></textarea></td>

                <td>
                  <v-icon style="color: #dc3545;" small v-if="sub_items.length > 1" @click="removeRepeaterItem(index)">mdi-delete</v-icon>

                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('work_order.operations') }}</h6>
            <button type="button" class="btn btn-primary" @click="addRepeater"><i class="fas fa-plus"></i>{{ $t('add_more') }}</button>
          </div>
          <div class="table-responsive bg-white">
            <table class="table table-custom-padding">
              <thead>
              <tr>
                <th scope="col">
                  <div class="d-flex justify-content-between">
                    <span>{{ $t('work_order.operation') }}</span>
                    <a href="/manufactories/operation-management/create" target="_blank" class="btn btn-primary btn-sm p-1">
                      <i class="fa fa-plus add-new"></i>
                    </a>
                  </div>
                </th>
                <th scope="col">{{ $t('work_order.status') }}</th>
                <th scope="col">
                  <div class="d-flex justify-content-between">
                    <span>{{ $t('work_order.workstation') }}</span>
                    <a href="/manufactories/workstation/create" target="_blank" class="btn btn-primary btn-sm p-1">
                      <i class="fa fa-plus add-new"></i>
                    </a>
                  </div>
                </th>
                <th scope="col">{{ $t('work_order.operation_time') }}</th>
                <th scope="col">{{ $t('work_order.output_quantity') }}</th>
                <th scope="col">{{ $t('actions') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in sub_operations" :key="index">
                <td>
                  <multiselect v-model="item.operation"
                               :placeholder="$t('work_order.operation')"
                               :disabled="item.is_disabled"
                               label="name"
                               track-by="id"
                               :options="operations"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @input="selectItem(index, 'operation')">
                  </multiselect>
                </td>
                <td>
                  <select name="" v-model="item.status" class="custom-select">
                    <option v-for="row in operations_status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                  </select>
                </td>

                <td>
                  <multiselect v-model="item.workstation"
                               :placeholder="$t('work_order.workstation')"
                               label="name"
                               track-by="id"
                               :options="workstations"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @input="selectItem(index, 'workstation')">
                  </multiselect>
                </td>
                <td>
                  <input type="number" min="0" v-model="item.operation_time" class="form-control" :placeholder="$t('work_order.operation_time')"/>
                </td>
                <td>
                  <input type="number" min="0" v-model="item.output_quantity" class="form-control" :placeholder="$t('work_order.output_quantity')"/>
                </td>
                <td>
                  <button type="button" v-if="item.use_delete" class="btn btn-link mr-2" @click="removeRepeater(index)"><i class="fas fa-trash text-danger"></i></button>
                </td>
              </tr>


              </tbody>
            </table>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-3 mb-5">
                <label>{{ $t('work_order.planned_operation_cost') }}</label>
                <input type="number" min="0" v-model="data.planned_operation_cost" class="form-control" :class="validation && validation.planned_operation_cost ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.planned_operation_cost" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.planned_operation_cost[0] }}
                            </span>
              </div>
              <div class="col-lg-3 mb-5">
                <label>{{ $t('work_order.total_operation_cost') }}</label>
                <input type="number" min="0" v-model="data.total_operation_cost" class="form-control" :class="validation && validation.total_operation_cost ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.total_operation_cost" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.total_operation_cost[0] }}
                            </span>
              </div>
              <div class="col-lg-3 mb-5">
                <label>{{ $t('work_order.actual_operation_cost') }}</label>
                <input type="number" min="0" v-model="data.actual_operation_cost" class="form-control" :class="validation && validation.actual_operation_cost ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.actual_operation_cost" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.actual_operation_cost[0] }}
                            </span>
              </div>
              <div class="col-lg-3 mb-5">
                <label>{{ $t('work_order.additional_operation_cost') }}</label>
                <input type="number" min="0" v-model="data.additional_operation_cost" class="form-control" :class="validation && validation.additional_operation_cost ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.additional_operation_cost" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.additional_operation_cost[0] }}
                            </span>
              </div>
              <div class="col-lg-3 mb-5">
                <label>{{ $t('work_order.start_date_time') }}</label>
                <input type="datetime-local" disabled v-model="data.start_date_time" class="form-control" :class="validation && validation.start_date_time ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.start_date_time" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.start_date_time[0] }}
                            </span>
              </div>
              <div class="col-lg-3 mb-5">
                <label>{{ $t('work_order.expected_delivery_date_time') }}</label>
                <input type="datetime-local" v-model="data.expected_delivery_date_time" class="form-control" :class="validation && validation.expected_delivery_date_time ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.expected_delivery_date_time" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.expected_delivery_date_time[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";

export default {
  name: "Form-work-order",
  data() {
    return {
      mainRoute: 'manufacturers/work_orders',
      mainRouteBillMaterial: 'manufacturers/bill_materials',
      mainRouteDependency: 'base/dependency',

      data: {
        title: null,
        expected_qty: null,
        item_id: null,
        bill_material_id: this.$route.params.bill_id ? this.$route.params.bill_id : null,
        sales_no: null,
        project_no: null,
        notes: null,
        status: 1,
        actual_quantity: null,
        in_progress_inventory_id: null,
        target_inventory_id: null,
        scrap_inventory_id: null,
        skip_material_transfer: false,
        use_multilevel_bom: false,
        planned_operation_cost: 0,
        total_operation_cost: 0,
        actual_operation_cost: 0,
        additional_operation_cost: 0,
        start_date_time: null,
        expected_delivery_date_time: null,
      },

      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,

      validation: null,

      item: null,
      items: [],
      items_manufacture: [],
      f_by: 'name',

      bill_material: null,
      bill_material_list: [],

      inventories: [],
      in_progress_inventory: null,
      target_inventory: null,
      scrap_inventory: null,

      workstations: [],
      status_list: [],
      operations: [],

      sub_items: [],
      sub_item_repeater: {id: null, item_id: null, item: null, origin_quantity: null, required_quantity: null, inventory: null, inventory_source_id: null, notes: null, is_disabled: false},
      items_repeater_select: [],

      sub_operations: [],
      sub_operations_repeater: {id: null, operation: null, operation_id: null, workstation: null, workstation_id: null, operation_time: null, output_quantity: null, status: 1, is_disabled: false},
      // operations_repeater_select: [],
      operations_status_list: [],
    }
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    bill_material: function (val) {
      if (val) {
        this.data.bill_material_id = val.id;
      } else
        this.data.bill_material_id = null;
    },
    item: function (val) {
      if (val)
        this.data.item_id = val.id;
      else
        this.data.item_id = null;
    },
    in_progress_inventory: function (val) {
      if (val)
        this.data.in_progress_inventory_id = val.id;
      else
        this.data.in_progress_inventory_id = null;
    },
    target_inventory: function (val) {
      if (val)
        this.data.target_inventory_id = val.id;
      else
        this.data.target_inventory_id = null;
    },
    scrap_inventory: function (val) {
      if (val)
        this.data.scrap_inventory_id = val.id;
      else
        this.data.scrap_inventory_id = null;
    },

    sub_items: {
      handler(val) {
        if (val.length > 0) {
          this.sub_items.map((row) => {
            if (this.data.expected_qty)
              row.required_quantity = (this.data.expected_qty ? parseInt(this.data.expected_qty) : 0) * (row.origin_quantity ? parseFloat(row.origin_quantity) : 0);
          });
        }
      },
      deep: true
    },
    "data.expected_qty": function (val) {
      this.sub_items.map((row) => {
        row.required_quantity = (val ? parseInt(val) : 0) * (row.origin_quantity ? parseFloat(row.origin_quantity) : 0);
      });
    },

  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
        items: this.sub_items,
        operations: this.sub_operations,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);

        this.$router.push({name: 'work-order.index'});
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },


    update() {
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
        items: this.sub_items,
        operations: this.sub_operations,

      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push({name: 'work-order.index'});
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    async getData() {

      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        if (this.$route.params.is_clone == 'clone') {
          this.isEditing = false;
        } else {
          this.isEditing = true;
        }

        this.data.title = response.data.data.title;
        this.data.expected_qty = response.data.data.expected_qty;
        this.data.item_id = response.data.data.item_id;
        this.data.bill_material_id = response.data.data.bill_material_id;
        this.data.sales_no = response.data.data.sales_no;
        this.data.project_no = response.data.data.project_no;
        this.data.notes = response.data.data.notes;

        if (this.$route.params.is_clone == 'clone') {
          this.data.status = 1;
          this.data.start_date_time = null;
        } else {
          this.data.status = response.data.data.status;
          this.data.start_date_time = response.data.data.start_date_time;
        }

        this.data.actual_quantity = response.data.data.actual_quantity;
        this.data.in_progress_inventory_id = response.data.data.in_progress_inventory_id;
        this.data.target_inventory_id = response.data.data.target_inventory_id;
        this.data.scrap_inventory_id = response.data.data.scrap_inventory_id;
        this.data.skip_material_transfer = response.data.data.skip_material_transfer;
        this.data.use_multilevel_bom = response.data.data.use_multilevel_bom;
        this.data.planned_operation_cost = response.data.data.planned_operation_cost;
        this.data.total_operation_cost = response.data.data.total_operation_cost;
        this.data.actual_operation_cost = response.data.data.actual_operation_cost;
        this.data.additional_operation_cost = response.data.data.additional_operation_cost;

        this.item = response.data.data.item;
        this.bill_material = response.data.data.bill_material;
        this.in_progress_inventory = response.data.data.in_progress_inventory;
        this.target_inventory = response.data.data.target_inventory;
        this.scrap_inventory = response.data.data.scrap_inventory;

        if (response.data.data.items && response.data.data.items.length > 0) {
          this.sub_items = response.data.data.items;
          if (this.$route.params.is_clone == 'clone') {
            this.sub_items = response.data.data.items.map((row) => {
              row.id = null;
              return row;
            });
          }
        } else {
          this.addRepeaterItem();
        }

        if (response.data.data.operations && response.data.data.operations.length > 0) {
          this.sub_operations = response.data.data.operations;
          if (this.$route.params.is_clone == 'clone') {
            this.sub_operations = response.data.data.operations.map((row) => {
              row.id = null;
              row.status = 1;
              row.start_date = null;
              row.stop_date = null;
              return row;
            });
          }
        } else {
          this.addRepeater();
        }

        this.data.expected_delivery_date_time = response.data.data.expected_delivery_date_time;

      });
    },

    async getBillMaterial(event = null) {
      if (event) {
        this.data.bill_material_id = event.id;
      }

      ApiService.get(`${this.mainRouteBillMaterial}/${this.data.bill_material_id}`).then((response) => {

        this.data.item_id = response.data.data.item_id;
        this.item = response.data.data.item;
        this.data.expected_qty = response.data.data.quantity;

        if (response.data.data.sub_items && response.data.data.sub_items.length > 0) {
          this.sub_items = response.data.data.sub_items.map((row) => {
            this.items_repeater_select.push(row.item);
            return {
              id: null,
              item_id: row.item_id,
              item: row.item,
              origin_quantity: row.origin_quantity,
              required_quantity: row.required_quantity,
              inventory: row.inventory,
              inventory_source_id: row.inventory_id,
              notes: null,
              is_disabled: true,
            }
          });
        } else {
          this.addRepeaterItem();
        }

        if (response.data.data.operations && response.data.data.operations.length > 0) {
          this.sub_operations = response.data.data.operations.map((row) => {
            return {
              id: null,
              operation: row.operation,
              operation_id: row.operation_management_id,
              workstation: row.workstation,
              workstation_id: row.workstation_id,
              operation_time: row.operation_time_minutes,
              output_quantity: null,
              status: 1,
              is_disabled: true,
            }
          });
        } else {
          this.addRepeater();
        }


      });
    },


    getItemsManufacture(f_by = null, filter = null) {
      if (filter && filter.length >= 3 && f_by)
        ApiService.get(this.mainRouteDependency + "/items_manufacture", {params: {[f_by]: filter}}).then((response) => {
          this.items_manufacture = response.data.data;
        });
    },
    getItems(f_by = null, filter = null) {
      if (filter && filter.length >= 3 && f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + `/inventories`).then((response) => {
        this.inventories = response.data.data;
      });
    },
    getWorkstations() {
      ApiService.get(this.mainRouteDependency + "/workstations").then((response) => {
        this.workstations = response.data.data;
      });
    },
    getOperations() {
      ApiService.get(this.mainRouteDependency + "/operations").then((response) => {
        this.operations = response.data.data;
      });
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/work_order_status").then((response) => {
        this.status_list = response.data.data;
      });
    },
    getOperationsStatusList() {
      ApiService.get(this.mainRouteDependency + "/work_order_operations_status").then((response) => {
        this.operations_status_list = response.data.data;
      });
    },
    getBillMaterialsList() {
      ApiService.get(this.mainRouteDependency + "/bill_materials").then((response) => {
        this.bill_material_list = response.data.data;
        this.bill_material = response.data.data.find(row => row.id == this.data.bill_material_id);
      });
    },

    addRepeaterItem() {
      this.sub_items.push(this.sub_item_repeater);
      this.sub_item_repeater = {
        id: null,
        item_id: null,
        item: null,
        origin_quantity: null,
        required_quantity: null,
        inventory: null,
        inventory_source_id: null,
        notes: null,
        is_disabled: false
      };
    },
    removeRepeaterItem(index = 0) {
      if (this.sub_items.length > 1)
        this.sub_items.splice(index, 1);
    },

    checkItemSelected(index, event) {

      let item_found = null;
      this.sub_items.forEach((row, _index) => {

        if (_index != index && row.item.id == event.id) {
          item_found = row;
        }
      });
      if (item_found) {
        this.sub_items[index].item = null;
      }
    },

    addRepeater() {
      this.sub_operations.push(this.sub_operations_repeater);
      this.sub_operations_repeater = {
        id: null,
        operation: null,
        operation_id: null,
        workstation: null,
        workstation_id: null,
        operation_time: null,
        status: 1,
        output_quantity: null,
        is_disabled: false
      };
    },
    removeRepeater(index) {
      if (!this.sub_operations[index].id) {
        this.last_sequence = this.last_sequence - 1;
      }
      if (this.sub_operations.length > 1)
        this.sub_operations.splice(index, 1);
    },
    selectItem(index, type) {
      if (type == 'operation')
        this.sub_operations[index].operation_id = this.sub_operations[index].operation ? this.sub_operations[index].operation.id : null;
      else if (type == 'workstation')
        this.sub_operations[index].workstation_id = this.sub_operations[index].workstation ? this.sub_operations[index].workstation.id : null;
    },
    // calcTime(){
    //         let _time_as_minutes = 0;
    //         this.sub_operations.map((row) => {
    //             if (row.operation_time)
    //                 _time_as_minutes  += (row.operation_time ? parseInt(row.operation_time) :0);
    //         });
    //
    //         if (this.data.start_date_time)
    //             this.data.expected_delivery_date_time =  this.$moment(this.data.start_date_time, "YYYY-MM-DD hh:mm:ss")
    //                 .add(_time_as_minutes, 'minutes')
    //                 .format("YYYY-MM-DD hh:mm:ss");
    // },
    // getDefaultInventory() {
    //   ApiService.get(this.mainRouteDependency + "/default-inventory").then((response) => {
    //     this.in_progress_inventory = response.data.data;
    //     this.target_inventory = response.data.data;
    //     this.scrap_inventory = response.data.data;
    //
    //     this.sub_item_repeater.inventory = response.data.data;
    //     if (this.sub_items[0]) {
    //       this.sub_items[0].inventory = response.data.data;
    //     }
    //   });
    // },
    // getDefaultWorkstation() {
    //   ApiService.get(this.mainRouteDependency + "/default-workstation").then((response) => {
    //
    //     this.sub_operations_repeater.workstation = response.data.data;
    //     if (this.sub_items[0]) {
    //       this.sub_operations[0].workstation = response.data.data;
    //     }
    //   });
    // },

    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {

        this.in_progress_inventory = response.data.data.inventory;
        this.target_inventory = response.data.data.inventory;
        this.scrap_inventory = response.data.data.inventory;
        this.sub_item_repeater.inventory = response.data.data.inventory;
        if (this.sub_items[0]) {
          this.sub_items[0].inventory = response.data.data.inventory;
        }

        this.sub_operations_repeater.workstation = response.data.data.workstation;
        if (this.sub_items[0]) {
          this.sub_operations[0].workstation = response.data.data.workstation;
        }
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.work_order"), route: '/manufactories/work-order'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);


    this.getBillMaterialsList();
    this.getInventories();
    this.getWorkstations();
    this.getOperations();
    this.getStatusList();
    this.getOperationsStatusList();

    if (this.idEditing) {
      this.getData();
    } else {
      this.defaultDataForUser();

      if (this.data.bill_material_id) {
        this.getBillMaterial();
      } else {
        this.addRepeater();
        this.addRepeaterItem();
      }
    }
  }
}
</script>

<style scoped>

</style>